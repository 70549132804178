
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";

import Card from "primevue/card";
import Button from "primevue/button";
import AccordionTab from "primevue/accordiontab";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import CheckBox from "primevue/checkbox";
import TextArea from "primevue/textarea";
import Dialog from "primevue/dialog";

import CustomAccordion from "@/components/UI/CustomAccordion.vue";
import VendorAutocomplete from "@/components/Autocompletes/VendorAutocomplete.vue";

import TermsService from "@/services/TermsService";
import ControlService from "@/services/ControlService";
import AccountsPayable from "@/types/accountsPayable";
import Utils from "@/utility/utils";

const termsService = new TermsService(process.env.VUE_APP_ABSTRACTION_API);
const controlService = new ControlService();

export default defineComponent({
  name: "PayableHeaderTab",
  components: {
    Card,
    Button,
    CustomAccordion,
    AccordionTab,
    Divider,
    InputText,
    InputNumber,
    Dropdown,
    VendorAutocomplete,
    Calendar,
    DataTable,
    Column,
    CheckBox,
    TextArea,
    Dialog,
  },

  data: () => ({
    payable: {} as AccountsPayable,
    currentId: "",
    reg_date: "",
    itemsPerPage: 5,
    terms: [] as any[],
    currencyCodesOptions: [] as any[],
    currencyCode: {} as any,
    status: [
      { status: "Accrual", initial: "A" },
      { status: "Precheck and Processing", initial: "P" },
      { status: "Open", initial: "O" },
      { status: "Closed", initial: "C" },
    ],
    freightReceipts: [] as any[],
    miscCharges: [] as any[],
    miscAmount: "",
    miscAccount: "",
    miscDescription: "",
    freightReceipt: "",
    showFreightDialog: false,
    showMiscDialog: false,
  }),

  computed: {
    ...mapGetters({
      getActiveOrderTab: "accountingInquiry/getActiveOrderTab",
      getCompanyCodes: "control/getCompanyCodes",
    }),
    isReadOnly(): boolean {
      return this.$attrs.readOnly === true;
    },
    computedMiscTotal(): string {
      const items = this.miscCharges;
      if (!items || items.length === 0) {
        return "$0.00";
      }
      return this.formatPrice(
        items
          .reduce(
            (acc: number, item: any) => acc + parseFloat(item.miscAmount),
            0,
          )
          .toString(),
      );
    },
  },

  async created() {
    if (!this.$attrs.payableId) {
      this.$router.push("/accounting/");
    }
    this.getCurrencyCode();
    this.terms = (await termsService.getTerms()) as any[];

    let id = this.$attrs.payableId as string;
    if (
      (await this.getActiveOrderTab(id)) &&
      (await this.getActiveOrderTab(id).record) !== null
    ) {
      this.payable = (await this.getActiveOrderTab(id).record)
        ? ((await this.getActiveOrderTab(id).record) as AccountsPayable)
        : ({} as AccountsPayable);
    }
    this.currentId = id;

    if (this.getCompanyCodes === null || this.getCompanyCodes.length === 0) {
      await this.fetchCompanyCodes();
    }
    this.initPayable(this.payable, "created");
  },

  async updated() {
    let id = this.$attrs.payableId as string;
    if (
      (await this.getActiveOrderTab(id)) &&
      (await this.getActiveOrderTab(id).record) !== null
    ) {
      this.payable = (await this.getActiveOrderTab(id).record)
        ? ((await this.getActiveOrderTab(id).record) as AccountsPayable)
        : ({} as AccountsPayable);
    }
    this.currentId = id;

    this.initPayable(this.payable);
  },

  methods: {
    ...mapActions({
      fetchControl: "control/fetchControl",
      pushAccountingLineItemMap: "accountingInquiry/pushAccountingLineItemMap",
    }),
    getTwoColumnDivClass() {
      return "xl:col-6 px-0 py-1";
    },
    getBaseDivClass() {
      return "col-12 sm:col-6 lg:col-12 p-1 m-0";
    },
    getFreightDivClass() {
      return "col-9 sm:col-6 lg:col-9 p-1 m-0";
    },
    getFreightTwoColumnDivClass() {
      return "xl:col-12 sm:col-9 xs: col-9 px-0 py-1";
    },
    handleLineItemClick(data: any, payableId: string) {
      this.pushAccountingLineItemMap({ accountId: payableId, lineItem: data });
    },
    getDescription(descriptionItems: any[]) {
      if (descriptionItems && descriptionItems.length > 0) {
        return descriptionItems.map((item) => item.description).join("\n");
      }
      return "";
    },
    async initPayable(payable: any, hookName = "updated") {
      if (!payable.co_code) {
        payable.co_code = (await this.getCompanyCodes[0].code) ?? "";
      }

      if (payable.reg_date_items && payable.reg_date_items.length > 0) {
        this.reg_date = payable.reg_date_items[0].reg_date ?? "";
      } else {
        this.reg_date = "";
      }

      if (!payable.disc_pct) {
        payable.disc_pct = "0.00";
      }

      if (hookName === "created") {
        payable.status = payable.status ? payable.status : "O";
        payable.freight_amt = payable.freight_amt
          ? payable.freight_amt
          : "0.00";
        payable.sale_tax_amt = payable.sale_tax_amt
          ? payable.sale_tax_amt
          : "0.00";
      }
    },
    handleSavePayable() {
      console.log("Save payable");
    },
    handleVendorSelected(vendor: any) {
      this.payable.vendor = vendor.vendor_id;
      this.payable.vendor_name = vendor.name;
    },
    async getCurrencyCode() {
      let response = (await controlService.getControl(
        "CURRENCY",
        "CURRENCY.CONTROL",
        "CONTROL",
        "",
      )) as any[];
      if (response.length > 0 && response[0].currency_code_items.length > 0) {
        this.currencyCodesOptions = response[0].currency_code_items;
        this.currencyCode = this.currencyCodesOptions[0];
      }
    },
    async fetchCompanyCodes() {
      await this.fetchControl({
        id: "COMPANY",
        procedure: "CO.CONTROL",
        filename: "CONTROL",
      });
    },
    formatPrice(amt: string) {
      if (!amt || parseInt(amt) === 0) {
        return "";
      } else {
        return Utils.formatPrice(amt);
      }
    },
    handleAmountToPayChange(event: any) {
      this.payable.disc_amount = (
        (event.value * parseFloat(this.payable.disc_pct ?? "0")) /
        100
      ).toFixed(2);
    },
    handleDiscPctChange(event: any) {
      this.payable.disc_amount = (
        (parseFloat(this.payable.amt_to_pay ?? "0") * event.value) /
        100
      ).toFixed(2);
    },
    handleShowFreightReceipt() {
      this.showFreightDialog = true;
    },
    handleShowMiscDialog() {
      this.showMiscDialog = true;
    },
    addFreightReceipt() {
      const newFreightReceipt = {
        freightReceipt: this.freightReceipt,
      };
      this.freightReceipts.push(newFreightReceipt);
      this.freightReceipt = "";
      this.showFreightDialog = false;
    },
    addMiscCharge() {
      if (this.miscCharges) {
        const newMiscCharge = {
          miscAmount: this.miscAmount,
          miscAccount: this.miscAccount, // Ensure uniqueness
          miscDescription: this.miscDescription,
        };
        this.miscCharges.push(newMiscCharge);
        this.showMiscDialog = false;

        this.miscAmount = "";
        this.miscAccount = "";
        this.miscDescription = "";
      }
    },
  },

  watch: {
    reg_date(newVal) {
      if (!this.payable.reg_date_items) {
        this.payable.reg_date_items = [];
        this.payable.reg_date_items.push({
          reg_date: newVal,
          reg_invoice_amt: "0",
          reg_id: "",
        });
      }
      this.payable.reg_date_items[0].reg_date = newVal;
    },
    "payable.terms_code"(newVal) {
      if (newVal !== undefined && newVal.includes("%")) {
        let discPct = newVal.split("%")[0];
        this.payable.disc_pct = discPct;
        this.handleDiscPctChange({ value: discPct });
      }
    },
  },
});
